body {
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
  margin: 0px;
}

#start_button {
  display: none;
}

#main_video {
  display: none;
  width: 100%;
}

#main_iframe {
  display: none;
  width: 1920px;
  height: 1080px;
  transform-origin: top left;
}
